<template>
  <a-row :gutter="20">
    <a-col :span="9">
      <div class="mb-8">
        <a-input-search placeholder="请输入名称" @change="handleChange" />
      </div>
      <a-space class="mb-8">
        <a-button type="primary" @click="handleClickAdd">
          <template #icon>
            <component :is="icons['PlusOutlined']" />
          </template>
          新增
        </a-button>
        <a-button @click="handleClickDelete">
          <template #icon>
            <component :is="icons['DeleteOutlined']" />
          </template>
          批量删除
        </a-button>
        <a-button @click="$message.info('敬请期待～')">
          <template #icon>
            <component :is="icons['ExportOutlined']" />
          </template>
          批量导出
        </a-button>
      </a-space>
      <a-spin :spinning="spinning">
        <a-tree
          class="iss-tree"
          checkable
          check-strictly
          block-node
          v-model:expanded-keys="expandedKeys"
          v-model:checked-keys="checkedKeys"
          :selected-keys="selectedKeys"
          :tree-data="treeData"
          :replace-fields="{ key: 'id' }"
          @select="handleSelect"
        >
          <template #title="{ label, icon, isEnable }">
            <div class="iss-tree-item">
              <span class="item-title">{{ label }}</span>
              <a-badge :status="isEnable ? 'success' : 'error'" />
              <component :is="icons[icon || 'FileTextOutlined']" />
            </div>
          </template>
        </a-tree>
      </a-spin>
    </a-col>
    <a-col :span="15">
      <a-card :title="form.id ? '编辑' : '新增'" class="iss-card">
        <a-tabs size="small" v-model:activeKey="activeKey">
          <a-tab-pane tab="详情" key="one">
            <a-form
              class="iss-form"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-form-item label="父级">
                <span>{{ form.parentName }}</span>
              </a-form-item>
              <a-form-item label="菜单标题" v-bind="validateInfos.name">
                <a-input v-model:value="form.name" />
              </a-form-item>
              <a-form-item label="路由名称" v-bind="validateInfos.menuKey">
                <a-input v-model:value="form.menuKey" />
              </a-form-item>
              <a-form-item label="路由路径" v-bind="validateInfos.path">
                <a-input v-model:value="form.path" />
              </a-form-item>
              <a-form-item label="接入方式">
                <a-radio-group v-model:value="form.accessWay">
                  <a-radio value="builtin">内置页面</a-radio>
                  <a-radio value="qiankun">Qiankun</a-radio>
                  <a-radio value="iframe">Iframe</a-radio>
                  <a-radio value="link">第三方跳转</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="组件地址" v-bind="validateInfos.component">
                <a-input
                  v-model:value="form.component"
                  :prefix="form.accessWay === 'builtin' ? 'views/' : ''"
                  placeholder="请输入组件地址"
                />
              </a-form-item>
              <a-form-item label="图标">
                <a-input-search
                  v-model:value="form.icon"
                  placeholder="请选择图标"
                  @search="visible = true"
                >
                  <template #enterButton>
                    <a-button>
                      <component
                        :is="icons[form.icon || 'InfoCircleOutlined']"
                      />
                    </a-button>
                  </template>
                </a-input-search>
              </a-form-item>
              <a-form-item label="状态">
                <a-switch
                  v-model:checked="form.isEnable"
                  checked-children="启用"
                  un-checked-children="禁用"
                />
              </a-form-item>
              <a-form-item label="排序">
                <a-input-number v-model:value="form.sortValue" :min="0" />
              </a-form-item>
              <a-form-item label="分组">
                <a-input v-model:value="form.group" />
              </a-form-item>
              <a-form-item label="描述">
                <a-textarea v-model:value="form.describe" />
              </a-form-item>
              <a-form-item
                :wrapperCol="{ offset: 5, span: 16 }"
                class="ta-right"
              >
                <a-button type="primary" @click="handleSave">保存</a-button>
              </a-form-item>
            </a-form>
          </a-tab-pane>
          <a-tab-pane tab="操作权限" key="two" :disabled="!form.id">
            <iss-resources :code="$route.path" :menuId="form.id" />
          </a-tab-pane>
        </a-tabs>
      </a-card>
    </a-col>
  </a-row>
  <icon-modal
    v-model:visible="visible"
    :init-value="form.icon"
    @fnOk="value => (form.icon = value)"
  />
</template>

<script>
import { createVNode, reactive, ref, toRaw, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';
import {
  Badge,
  Card,
  Col,
  Form,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tabs,
  Tree,
} from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import IconModal from '@/components/iconModal';
import IssResources from '@/components/resources';
import { filterTree, getTreeItem } from '@/utils';
import menuApi from '@/api/menu.js';

export default {
  components: {
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    ASpin: Spin,
    ATree: Tree,
    ABadge: Badge,
    ACard: Card,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AForm: Form,
    AFormItem: Form.Item,
    ASwitch: Switch,
    AInputNumber: InputNumber,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    IconModal,
    IssResources,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      spinning: false,
      treeData: [],
      expandedKeys: [],
      selectedKeys: [],
      checkedKeys: { checked: [] },
    });
    const activeKey = ref('one');
    const componentType = ref('1');
    const visible = ref(false);
    const form = reactive({
      id: '',
      parentId: 0,
      parentName: '根结点',
      name: '',
      menuKey: '',
      accessWay: 'builtin',
      path: '',
      icon: '',
      isEnable: true,
      sortValue: 0,
      component: 'index.vue',
      group: '',
      describe: '',
    });
    const required = { required: true, message: '不能为空' };
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      name: [required],
      menuKey: [required],
      path: [required],
      component: [required],
    });
    let cacheTrees = [];
    const initTreeData = () => {
      state.spinning = true;
      menuApi
        .getList(route.path)
        .then(data => {
          cacheTrees = data.concat();
          state.treeData = data;
          state.expandedKeys = data.map(i => i.id);
        })
        .finally(() => {
          state.spinning = false;
        });
    };
    const handleClickAdd = () => {
      activeKey.value = 'one';
      state.selectedKeys = [];
      resetFields();
      const checked = state.checkedKeys.checked;
      if (checked.length == 1) {
        form.parentId = checked[0];
        form.parentName = getTreeItem(
          cacheTrees,
          item => item.id === checked[0]
        ).name;
      } else if (checked.length > 1) {
        message.info('只能选中一条数据新增其子节点');
      }
    };
    watch(
      [() => form.path, () => form.accessWay],
      ([path, accessWay], [, prevAccessWay]) => {
        if (path && !path.startsWith('/')) {
          form.path = `/${path}`;
        }
        switch (accessWay) {
          case 'builtin':
            form.component ||
              (form.component = `${form.path.substring(1)}/index.vue`);
            break;
          default:
            if (
              prevAccessWay === 'builtin' &&
              !form.component.startsWith('http')
            ) {
              form.component = '';
            }
            break;
        }
      }
    );
    initTreeData();
    return {
      icons,
      activeKey,
      componentType,
      visible,
      ...toRefs(state),
      form,
      validateInfos,
      handleClickAdd,
      handleSave: () => {
        validate().then(() => {
          let type = 'add';
          form.id && (type = 'update');
          menuApi[type](`menu:${type}`, toRaw(form)).then(() => {
            message.success('操作成功');
            handleClickAdd();
            initTreeData();
          });
        });
      },
      handleClickDelete: () => {
        const ids = state.checkedKeys.checked;
        if (ids.length) {
          Modal.confirm({
            title: `确定要删除选中的 ${ids.length} 条数据吗？`,
            icon: createVNode(icons.ExclamationCircleOutlined),
            okType: 'danger',
            onOk: () => {
              menuApi.delete('org.delete', { ids }).then(() => {
                message.success('操作成功');
                initTreeData();
              });
            },
          });
        } else {
          message.info('请至少选择一条数据');
        }
      },
      handleChange: ({ target }) => {
        const { trees, parents } = filterTree(
          cacheTrees,
          item => item.name.indexOf(target.value) > -1
        );
        state.treeData = trees;
        state.expandedKeys = parents.map(i => i.id);
      },
      handleSelect: (keys, { selected, selectedNodes }) => {
        if (selected) {
          state.selectedKeys = keys;
          const node = selectedNodes[0].props.dataRef;
          Object.assign(form, node, {
            parentName:
              node.parentId === '0'
                ? '根结点'
                : getTreeItem(cacheTrees, item => item.id === node.parentId)
                    .name,
          });
        }
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-tree {
  height: calc(100vh - 194px);
  overflow-y: auto;
}
.iss-tree-item {
  display: flex;
  align-items: center;
  .item-title {
    flex: 1;
  }
  .anticon {
    font-size: 16px;
  }
}
.iss-form {
  height: calc(100vh - 271px);
  overflow-y: auto;
}
</style>
